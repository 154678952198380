import React, { Fragment } from 'react';
import { Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const body = () => {
  return (
    <Container>
      <div style={{ backgroundColor: '#F6F7F9', margin: '50px', padding: '100px' }}>
        Welcome! Begin your learning journey here.
        <p>Here you can learn coding, computer science fundamentals, aptitude and much more</p>
        <h1>Site under construction</h1>
      </div>
    </Container>
  );
}

export default body;