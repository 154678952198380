import React from 'react';
import Header from '../components/header';
import Body from '../components/body';

const homePage = () => {
  return (
    <div>
      <Header />
      <Body />
    </div>
  )
}

export default homePage;