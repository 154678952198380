import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import app from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyB1I6v2kaAj64SsFma5n1R9dR5o2o9IRpc",
  authDomain: "log2code-72c28.firebaseapp.com",
  projectId: "log2code-72c28",
  storageBucket: "log2code-72c28.appspot.com",
  messagingSenderId: "420632182680",
  appId: "1:420632182680:web:b58b3ffefc5abf46ceb5ed",
  measurementId: "G-TJCRPKTEMB"
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
app.initializeApp(firebaseConfig);
app.analytics();
reportWebVitals();
